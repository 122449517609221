/* Import MainStyles.css */
body {
  --wp--preset--font-size--small: 0.842em;
  --wp--preset--font-size--medium: 1.1em;
  --wp--preset--font-size--large: 1.25em;
  --wp--preset--font-size--x-large: 1.6em;
}
.has-small-font-size {
    font-size: var(--wp--preset--font-size--small);
}
.has-medium-font-size {
    font-size: var(--wp--preset--font-size--medium);
}
.has-large-font-size {
    font-size: var(--wp--preset--font-size--large);
}
.has-x-large-font-size {
    font-size: var(--wp--preset--font-size--x-large);
}


:root {
    overscroll-behavior: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}








 :root {
	font-family: "Courier New", Courier, monospace;
	color: var(--secondary-text-color);

/** ============================================================================
 *  Root Variables
 *  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 *
 *  For a
 *
 */
	
	/** - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
     *  Measurements
     */ 
	--content-max-width: 100vw;
	--content-min-width: 100vw;

	--max-radius:     999.0rem;
	--large-radius:     2.5rem;
	--medium-radius:    1.0rem;
	--small-radius:     0.5rem;
    --default-radius:   var(--medium-radius);

    --extra-large-padding: 4.0rem;
    --large-padding:       2.0rem;
    --medium-padding:      1.0rem;
    --small-padding:       0.5rem;
    --default-padding:     var(--small-padding);


	/** - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
     *  Color Palette
     */
	--site-white-color:         #FFFFFF;
	--site-cream-color:         #FFFED7;
	--site-beige-color:             #FEFFE1;
	--site-beige-color-transparent: #FEFFE100;
	--site-dark-beige-color:    #E3E1B3;
	--site-darkest-beige-color: #CEC298;
	--site-brown-color: 	    #7d6323;
	--site-dark-brown-color: 	#281f03;
	--site-light-gray-color:    #DFDFDF;
	--site-gray-color:          #BEBEBE;
	--site-dark-gray-color:     #585858;
	--site-darkest-gray-color:  #363636;
	--site-black-color:		    #000000;
	
	
    --site-magenta-color: #ee00a7;
	--site-purple-color: purple;
	--site-light-blue-color: #add8e6;
	--site-blue-color:   #5996F0;
	--site-dark-blue-color: #00008b;
	--site-green-color:  green;
	--site-yellow-color: yellow;
	--site-orange-color: #FBB03B;
	--site-red-color:    red;

	--primary-bg-color:   #1a1d1d; /* var(--site-cream-color); */
	--secondary-bg-color: #2c2e2d;/* var(--site-beige-color); */
	--tertiary-bg-color: #ee00a7; /* var(--site-white-color); */

	--primary-fg-color:   #99ecff;
	--secondary-fg-color: #ffffff;
	--tertiary-fg-color:  #ffffff;

	--primary-text-color:   rgb(243, 243, 255);
	--secondary-text-color: var(--site-gray-color);
	--tertiary-text-color:  var(--site-light-gray-color);

	--primary-accent-color:   #c4edb7;
	--secondary-accent-color: #9bd4a7;
	--tertiary-accent-color:  #68b871;

}
/* For larger displayss
*/
@media (min-width: 768px) and (orientation: portrait) {
    :root {
        --content-max-width: 85vw;
    }
}
@media (min-width: 768px) and (orientation: landscape) {
    :root {
        --content-max-width: 66.667vw;
    }
}


	/** - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
     *  Headings
     */
	h1, h2, h3, h4, h5, h6 {
		color: var(--primary-text-color);
		font-family: "Arial", sans-serif;
	}


	/** - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
     *  Links
     */
	a {
		text-decoration-style: none;
		text-decoration: none;
		color: var(--primary-accent-color);
		margin-bottom: .333rem;
	}

	a:hover {
		color: var(--secondary-accent-color);
	}

/** 
 *  ============================================================================
 */


body, .site {
	background-color: var(--primary-bg-color);
	width:            var(--content-max-width);
	max-width:        var(--content-max-width);
	min-height:       100vh;
	margin-left:      auto;
	margin-right:     auto;
	margin-top:       auto;
    overflow:         auto;
}

.content-outer-box {
	margin:     auto;
	min-height: 100vh;
	width:      100vw;
	max-width:	100vw;
}

.content-inner-box {
	margin:     auto;
	min-height: 100vh;
	width:      var(--content-max-width);
	max-width:  var(--content-max-width);
}


.post-box {
    border:        0.2rem solid #393939;
    border-radius: 0.5rem;
    margin:        0.5rem;
    padding:       1.0rem;

    width:      90%;
    max-width:  90%;
    min-height: 20.0rem;
}




