.text-button {
    background-color: lightgray;
    border: none;
    border-radius: 999rem;
    color: black;
    cursor: pointer;
    font-family: sans-serif;
    font-size: 0.6rem;
    line-height: 0.6rem;
    margin: 0.125rem;
    padding: 0.2rem 0.5rem 0.1rem 0.5rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background 250ms ease-in-out, 
                transform  150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    white-space: nowrap;
}