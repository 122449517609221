


/* ----------------------------------------------------------------
 * Content boxes
 * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

 .spacer {
	min-height: 10rem;
	height: 10rem;
	width: 100%;

 }

.content-outer-box {
	background-color: var(--primary-bg-color);
	margin:           auto;
	min-height:       100vh;
	width:            var(--content-max-width);
	max-width:		  100vw;
}

.content-inner-box {
    /* background-color: var(--secondary-bg-color); */
	margin:     auto;
	min-height: 100vh;
	width: var(--content-max-width);
	max-width: 100vw;
}

.page-title {
    color: var(--site-black-color);
    font-family: sans-serif;
    font-size: 4rem;
    font-weight: 400;
}
.page-title.inverse {
    color: var(--site-white-color);
}

.section-title {

}
.section-box {

}


.group-box {
	display:   flex;
	flex-wrap: wrap;
}
.group-box.no-wrap {
    flex-wrap: nowrap;
}

/* Horizontal group box */
.group-box.horizontal {
	flex-direction: row;
}
.group-box.horizontal.reverse {
	flex-direction: row-reverse;
}
.group-box.horizontal.h-align-center {
	justify-content: center;
}
.group-box.horizontal.h-align-leading {
	justify-content: flex-start;
}
.group-box.horizontal.h-align-trailing {
	justify-content: flex-end;
}
.group-box.horizontal.h-align-space-between {
	justify-content: space-between;
}
.group-box.horizontal.v-align-top {
	align-items: flex-start;
}
.group-box.horizontal.v-align-center {
	align-items: center;
}
.group-box.horizontal.v-align-bottom {
	align-items: flex-end;
}
.group-box.horizontal.v-align-space-between {
	align-items: space-between;
}
.group-box.horizontal.wrap-reverse {
	flex-wrap: wrap-reverse;
}


/* Vertical group box */
.group-box.vertical {
	flex-direction: column;
	flex-wrap: nowrap;
}
.group-box.vertical.reverse {
	flex-direction: column-reverse;
}
.group-box.vertical.h-align-center {
	align-items: center;
	align-content: center;
}
.group-box.vertical.h-align-leading {
	align-items: flex-start;
	align-content: flex-start;
}
.group-box.vertical.h-align-trailing {
	align-items: flex-end;
	align-content: flex-end;
}
.group-box.vertical.h-align-space-between {
	align-items: space-between;
	align-content: space-between;
}
.group-box.vertical.v-align-top {
	justify-content: flex-start;
}
.group-box.vertical.v-align-center {
	justify-content: center;
}
.group-box.vertical.v-align-bottom {
	justify-content: flex-end;
}
.group-box.vertical.v-align-space-between {
	justify-content: space-between;
}

/* TODO: Put in proper place */
/* Group box overflow */
.group-box.h-scrollable, .group-box.h-scrollable > * {
	flex-wrap: nowrap;
	overflow-x: scroll;
	overflow-wrap: unset;
	white-space: pre;
}
.group-box.v-scrollable, .group-box.h-scrollable > * {
	flex-wrap: nowrap;
	overflow-y: scroll;
	overflow-wrap: unset;
	white-space: pre;
}
.group-box.h-clip {
	overflow-x: clip;
}
.group-box.v-clip {
	overflow-y: clip;
}
.group-box.h-fixed {
	max-width: 100%;
}
.group-box.v-fixed {
	max-height: 100%;
}

/* Group box padding */
.group-box.padding-horizontal {
	padding-left: 1rem;
	padding-right: 1rem;
}



.page-box.h-align-center {
	display: table;
	margin: 0 auto;
}
