/** ****************************************************************************
 *  
 *  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 *
 *  
 *
 *  ****************************************************************************
 */

.color-organizer-outer-box {

    /* -------------------------------------------------------------------------
     * Variables
     * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
    
    --transparency-texture-scale: 1;

    --color-organizer-picker-size: 30rem;
    --color-organizer-bezel-size: 0.5rem;

    --color-organizer-trailing-menu-color: #e7e7e7;

    --color-organizer-background-color: #ffffff;

    --color-organizer-debug-color: magenta;

    --color-organizer-title-color: black;
    --color-organizer-border-color: rgb(165, 154, 121);

    --color-organizer-button-outline-color: rgb(151, 149, 143);
    --color-organizer-button-text-color: white;

    --color-organizer-slider-background-color: black;
    --color-organizer-slider-border-color: black;
    --color-organizer-slider-thumb-color: rgb(151, 149, 143);
    --color-organizer-slider-thumb-hover-color: red;

    --color-organizer-marker-background-color: white;
    --color-organizer-marker-border-color: rgb(151, 149, 143);

    --color-organizer-picker-border-color: rgb(151, 149, 143);

    --color-organizer-preview-border-color: black;


    /* -------------------------------------------------------------------------
     * Outermost Wrapper 
     * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
  
    align-items: center;
    background: var(--color-organizer-background-color);
    border: 0.05rem solid var(--color-organizer-border-color);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    width: fit-content;
    overflow: hidden;
    padding: 1vw;
}

/* Inner wrapper */

.color-organizer-inner-box {
    align-items: center;
    color: var(--color-organizer-title-color);
    display: flex;
    flex-direction: column;
    height: auto;
    max-width: 100vw;
    overflow: hidden;
    width: fit-content;
    /* width: var(--color-organizer-picker-size); */
}


.color-organizer-top-menu-box {
    display: flex;
    flex-direction: horizontal;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding-bottom: 0.85vw;
}


/** -----------------------------------------------------------------------------
 *  Picker
 *  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 */
 .color-organizer-picker-box {
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
    /* width: 100%; */
 }

 .color-organizer-picker-box > div {
    aspect-ratio: 1 / 1;
 }

 .color-organizer .color-organizer-picker:hover {
    cursor: pointer;
}

.color-organizer .color-organizer-picker {
    aspect-ratio:   1 / 1;
    background:     -moz-linear-gradient(to top, rgb(0, 0, 0), transparent), linear-gradient(to left, hsl(0, 100%, 50%) , rgb(255, 255, 255));
    border:         0.050rem solid var(--color-organizer-picker-border-color);
    border-radius:  0.125rem;
    box-sizing:         border-box;
    -moz-box-sizing:    border-box;
    -webkit-box-sizing: border-box;
        width: var(--color-organizer-picker-size);

}
.color-organizer-picker-box > .color-organizer-transparent-texture {
    aspect-ratio: 1 / 1;
    height: var(--color-organizer-picker-size);
    width: var(--color-organizer-picker-size);
}

.color-organizer .color-organizer-transparent-texture {
    background: repeating-conic-gradient(#cdcdcd 0% 25%, transparent 0% 50%) 50% / 0.7rem 0.7rem;
}

.color-organizer-hue-selector {
    width: 1.8rem;
    margin-left: 0.33rem;
    outline: 1px solid gray;
    background: linear-gradient(
        0deg,
        rgba(255,   0,   0, 1)   0%,
        rgba(255, 154,   0, 1)  10%,
        rgba(208, 222,  33, 1)  20%,
        rgba(79,  220,  74, 1)  30%,
        rgba(63,  218, 216, 1)  40%,
        rgba(47,  201, 226, 1)  50%,
        rgba(28,  127, 238, 1)  60%,
        rgba(95,   21, 242, 1)  70%,
        rgba(186,  12, 248, 1)  80%,
        rgba(251,   7, 217, 1)  90%,
        rgba(255,   0,   0, 1) 100%
    );
}



/* -----------------------------------------------------------------------------
 * Text
 * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

.color-organizer .color-organizer-button:hover {
    cursor: pointer;
}
.color-organizer .color-organizer-button {
    color: var(--color-organizer-button-text-color);
    display: flex;
    flex-wrap: nowrap;
    padding: 0.85vw 1.2vw;
    background-color: rgb(191, 191, 191);
    border-radius: 999vw;
    margin: auto;
    outline: 0.05rem solid var(--color-organizer-button-outline-color);
}
.color-organizer .color-organizer-button .color-organizer-text {
    margin: 0;
}

.color-organizer .color-organizer-title {
    font-size: 0.6rem;
    font-family: sans-serif;
    line-height: 0.5rem;
    text-transform: uppercase;
    margin: 0;

}

.color-organizer .color-organizer-debug-text {
    color: var(--color-organizer-debug-color);
    margin-bottom: 0;
    margin-top: 0;
}

.color-organizer .color-organizer-text {
    font-size: 0.6rem;
    font-family: sans-serif;
    line-height: 0.5rem;
    text-transform: uppercase;
}
.color-organizer .color-selector-box .selected {
    background-color: teal;
}

.color-organizer-palette-box {
    display: flex;
    flex-direction: horizontal;
    flex-wrap: nowrap;
    padding: 0.75rem 0.5rem 0.667rem 0.5rem;
}


/* -----------------------------------------------------------------------------
 * Sliders
 * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
.color-organizer-sliders-box {
    background-color: var(--color-organizer-trailing-menu-color);
    border-radius: 1rem 0 1rem 1rem;
    padding: 0.5rem;
}


/* -----------------------------------------------------------------------------
 * Menu bar
 * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

.color-organizer-menu-bar {
    display: flex;
    flex-direction: horizontal;
    flex-wrap: nowrap;
}

/* Leading menu bar */
.color-organizer-menu-bar-leading {
    background: var(--color-organizer-background-color);
    border-radius: 0 0 0.5rem 0;
    display: flex;
    flex-direction: horizontal;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0.25rem 1rem 0.25rem 1rem;

}
.color-organizer-menu-bar-leading-box {
    background-color: var(--color-organizer-trailing-menu-color);
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
}

/* Trailing menu bar */
.color-organizer-menu-bar-trailing {
    background-color: var(--color-organizer-trailing-menu-color);
    border-radius: 0.5rem 0.5rem 0 0;
    display: flex;
    flex-direction: horizontal;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0.25rem 1rem 0.25rem 1rem;
}
.color-organizer-menu-bar-trailing-box {
    background-color: var(--color-organizer-background-color);
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
}

 /* Sliders wrapper */
.color-organizer-menu-box {
    /* grid-area: 1 / 2 / 2 / 3; */
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    max-height: calc(var(--color-organizer-picker-size) / 1.618); 
    width: var(--color-organizer-picker-size);
    overflow-y: scroll;
    padding-top: 0.333rem;
}

/* Main Slider Class  */
.color-organizer .color-organizer-slider {
    -webkit-appearance: none !important;
    appearance: none !important;
    height: 0.125rem !important;
    background: var(--color-organizer-slider-background-color);
    outline: none !important;
    border-radius: 999rem !important;
}

.color-organizer #color-organizer-brightness-slider {
    margin-bottom: 1rem;
}

/* Slider Thumbs */
.color-organizer .color-organizer-slider::-webkit-slider-thumb, 
.color-organizer .color-organizer-slider::-moz-range-thumb:hover {
    background: var(--color-organizer-slider-thumb-hover-color);
}
.color-organizer .color-organizer-slider::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    appearance: none !important;
}
.color-organizer .color-organizer-slider::-webkit-slider-thumb, 
.color-organizer .color-organizer-slider::-moz-range-thumb {
    width: 0.8rem !important;
    height: 0.8rem !important;
    background: var(--color-organizer-slider-thumb-color) !important;
    border-radius: 100% !important;
    border: none !important;
    cursor: pointer !important;
}

.color-organizer .color-organizer-slider::-webkit-slider-thumb::before, 
.color-organizer .color-organizer-slider::-moz-range-thumb::before {
    width: 2rem !important;
    height: 2rem !important;
    background: var(--color-organizer-slider-thumb-color) !important;
    border-radius: 100% !important;
    cursor: pointer !important;
    border: 1px solid gray;
    z-index: 100;
    position: absolute;
    content: "";
}


/* -----------------------------------------------------------------------------
 * Marker
 * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

.color-organizer-marker {
    aspect-ratio:  1 / 1;
    position:      absolute;
    background:    var(--color-organizer-marker-background-color);
    border:        0.05rem solid var(--color-organizer-marker-border-color);
    border-radius: 100%;
    width:         0.5rem;
    height:        0.5rem;
    z-index:       99;
}











.color-organizer .color-swatch {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 2rem;
    height: 2rem;
    border: 0.025rem solid gray;
    margin: 0.05rem;
    position: relative;
}

.color-organizer .color-preview {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* width: 100%; */
    width: 4rem;
    height: 4rem;
    border: 0.025rem solid gray;
    position: relative;
}

.color-organizer .color-swatch.selected {
    border: 1px solid magenta;
}
