
.outlined-section-sleek-title {
    font-size: 0.75rem;
    font-weight: bold;
    position: relative;
    margin: -0.57rem 0 0 0;
    padding: 0 0.333rem;
    background: var(--primary-bg-color);
    white-space: nowrap;
    width: min-content;
    text-transform: uppercase;
}

.swift-tools-section-title {
    font-size: 0.75rem;
    font-weight: bold;
    position: relative;
    margin: -0.57rem 0 0 0;
    padding: 0 0.333rem;
    background: var(--primary-bg-color);
    white-space: nowrap;
    width: min-content;
    text-transform: uppercase;
}