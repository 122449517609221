:root {
	--header-height: 2rem;
	--header-z-index: 999999;
}

.header-box {
	position: fixed;
	display: flex;
	flex-wrap: nowrap;
	width: 100vw;
	height: var(--header-height);
	z-index: var(--header-z-index);
}

#header-front {
	width: auto;
	position: absolute; 
	height: var(--header-height);
	z-index: calc(var(--header-z-index) + 2);
	fill: var(--primary-bg-color);
}

.header-front {
	stroke-width: 0px;
}

.header-back {
	background-color: var(--site-black-color);
	height: var(--header-height);
	width: 100%;
	position: relative;
	z-index: calc(var(--header-z-index) + 1);
}
.header-back > * {
    margin-bottom: -0.5rem;
}

.header-logo {
	aspect-ratio: 1/1;
	height: var(--header-height);
	width: var(--header-height);
	background-image: url("../assets/images/Self\ Portrait\ 2023\ [1024px\ x\ 1024px].png");
	background-size: cover;
	margin: 0 0.333rem 0 0.5rem;
	position: relative;
	z-index: calc(var(--header-z-index) + 3);
}
.header-title:hover {
    color: lightblue;
}
.header-title {
    color: var(--primary-text-color);
    font-size: 1.25rem;
    font-weight: 600;
	margin: 0;
	min-width: fit-content;
	position: relative;
	z-index: calc(var(--header-z-index) + 3);
	white-space: nowrap;
}


.nav-item {
	color: var(--primary-text-color);
	font-size: 0.85rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-bottom: 0.2rem;
    line-height: 0.5rem;
}

.nav-item:hover {
	color: lightblue;
}
.nav-item-divider {
    width: 0.1rem;
    height: 0.667rem;
    background: var(--site-dark-gray-color);
}

.feed-view-button {
    line-height: 0.75rem;
    justify-content: center;
    align-items: top;
    display: flex;
    width: 1rem;
    height: 1rem;
    border: 1px solid white;
    border-top-left-radius: 0.1rem;
    border-bottom-left-radius: 0.1rem;
}
.feed-view-button ~ .feed-view-button {
    border-radius: 0;
    border-left: 0;
}
.feed-view-button:last-child {
    border-top-right-radius: 0.1rem;
    border-bottom-right-radius: 0.1rem;
}
.feed-view-button.selected {
    background-color: white;
}



 .divider {
	width: var(--content-max-width);
	height: 0.1rem;
	background: var(--site-gray-color);
 }



.heading-symbol {
	height: 4rem;
	width:  4rem;
	margin: auto -1rem auto -1rem;
}

.heading-text {
	font-family: sans-serif;
	display:     flex;
	align-items: center;
}

