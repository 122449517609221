.blakes-resume-box {
    width: 100%;
}

.blakes-resume-heading-box {
    width: 100%;
}

.blakes-resume-contact-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.blakes-resume-contact-box > * {
    padding-left: 1rem;
    padding-right: 1rem;
}
.blakes-resume-contact-box > *:first-child {
    padding-left: 0;
}
.blakes-resume-contact-box > *:last-child {
    padding-right: 0;
}

.blakes-resume-section-box {
    display: flex;
    flex-wrap: wrap;
}
.blakes-resume-section-box > * {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;   
}
.blakes-resume-section-title {
    font-size: 1.333rem;
    width: 100%;
    border-bottom: 1px solid gray;
}
.blakes-resume-subsection-box {
    border: 0.25rem solid gray;
    border-radius: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.blakes-resume-subsection-box > ul {
    padding-inline-start: 1rem;
    min-width: 20rem;
}
.blakes-resume-subsection-border-title {
    font-size: 0.75rem;
    font-weight: bold;
    position: absolute;
    margin: -0.57rem 0 0 0;
    padding: 0 0.333rem;
    background: var(--primary-bg-color);
    white-space: nowrap;
    width: min-content;
    text-transform: uppercase;
}

.blakes-resume-dates-box {
    font-size: 0.85rem;
    text-align: right;
    float: right;
    margin-top: 0.5rem;
}
.blakes-resume-dates-box > p {
    margin: 0.333rem 0;
}